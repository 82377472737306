.about {
  background-color: #ffbe00;
  /* margin: 10px 0px; */
}
.about .title h1 {
  color: rgb(0, 0, 0);
  text-align: center;
}
.about-her {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  color: rgb(0, 0, 0);
  padding: 20px 0px;
}
.about-her h2 {
  color: #ffffff;
  font-weight: 900;
}
.propert {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}
.propp {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 20px;
  gap: 10px;
  color: black;
  border-radius: 30px;
  /* margin-top:120px; */
}
.propp .icon {
  color: rgb(0, 129, 242);
  font-size: 30px;
}
.propp:hover {
  transform: scale(1.05);
  transition: 0.4s;
  cursor: pointer;
}
.hashtag {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.hashtag img {
  width: 30%;
}
.about-img-middle  {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  position: relative;
  /* height: 500px; */
}.about-img-middle .teacher{
  width: 100%!important;
}
.about-img-middle .button {
 background-color: #ffffff;
 padding: 20px;
 margin: auto;
 margin-top: 30px;
 font-size: 20px;
 border-radius: 20px;
 width: 50%;
 display: flex;
 justify-content: center;
 text-align: center;
 border: solid 1px #ffffff;
}
.about-img-middle .button:hover{
  background-color:transparent;
  transition: 0.4s;
  color: rgb(17, 14, 14);
}

.about-img-middle img {
  width: 100%;
}

.svg-animation text {
  color: rgb(255, 0, 0) !important;
  background-color: #ffffff !important;
  font-size: 70px !important;
}
