@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
/* GLOBAL RULES AND CLASSES */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Arial, Helvetica, sans-serif; */
  direction: rtl;
}
input:focus::placeholder {
  color: transparent !important;
}
body {
  position: relative;
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  display: inline-block;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  all: unset;
  cursor: pointer;
}
.hidden-lg {
  display: none !important;
}
.scrolled::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrolled {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* start header */
header.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 30;
}
header.header div.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  background-color: #fff;
  border-radius: 0 0 30px 30px;
  -webkit-border-radius: 0 0 30px 30px;
  -moz-border-radius: 0 0 30px 30px;
  -ms-border-radius: 0 0 30px 30px;
  -o-border-radius: 0 0 30px 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
header.header.remove-shadow {
  box-shadow: unset;
}
@media (max-width: 767px) {
  header.header {
    position: sticky;
    top: 0;
    z-index: 501;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  header.header div.navigation {
    box-shadow: unset;
  }
}
header.header div.navigation .logo {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
header.header div.navigation .logo .light-logo {
  display: none !important;
}
header.header div.navigation .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
header.header div.navigation .nav-links .links {
  margin-left: 15px;
}
header.header div.navigation .nav-links .links li {
  position: relative;
  padding: 10px 20px;
  font-weight: bold;
}
header.header div.navigation .nav-links .links li:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: 0;
  top: 50%;
  background-color: #333;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
header.header div.navigation .mobile-menu {
  font-size: 32px;
}
header.header div.navigation .nav-links .dark-light-mode-btn,
.mobile-menu-links .dark-light-mode .left-btn {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2px;
  background-color: #333;
  cursor: pointer;
}
header.header div.navigation .nav-links .dark-light-mode-btn span,
.mobile-menu-links .dark-light-mode .left-btn span {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
header.header div.navigation .nav-links .dark-light-mode-btn span.active,
.mobile-menu-links .dark-light-mode .left-btn span.active {
  background-color: #fff;
  color: #000 !important;
}
header.header div.navigation .nav-links .dark-light-mode-btn span,
.mobile-menu-links .dark-light-mode .left-btn span {
  color: #fff;
}
header.header div.navigation .user-image {
  width: 51px;
  height: 51px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
header.header div.navigation .user-auth {
  display: flex;
  align-items: center;
  position: relative;
}
header.header div.navigation .user-auth .user-info {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
header.header div.navigation .user-auth .user-info .user-image {
  width: 51px;
  height: 51px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-left: 10px;
}
header.header div.navigation .user-auth .user-info p {
  font-size: 16px;
}
header.header div.navigation .user-auth .bel {
  font-size: 20px;
  cursor: pointer;
}
header.header div.navigation .user-auth .logout-dropdown {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  width: fit-content;
  background-color: #fff;
  z-index: 5;
  border-radius: 0 0 8px 8px;
  padding: 10px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  opacity: 0;
  transition: all 0.5s;
}
header.header div.navigation .user-auth:hover .logout-dropdown {
  opacity: 1;
}
header.header div.navigation .user-auth .logout-dropdown li {
  width: 100%;
  padding-bottom: 10px;
  text-wrap: nowrap;
  font-size: 15px;
  transition: color 0.5s;
}
header.header div.navigation .user-auth .logout-dropdown li i {
  margin-left: 10px;
}
header.header div.navigation .user-auth .logout-dropdown li:first-child {
  border-bottom: 1px solid #1e1e1e;
  margin-bottom: 10px;
}
header.header div.navigation .user-auth .logout-dropdown li:first-child:hover {
  color: #0278ff;
}
header.header div.navigation .user-auth .logout-dropdown li:nth-child(2) {
  cursor: pointer;
}
header.header div.navigation .user-auth .logout-dropdown li:nth-child(2):hover {
  color: #ff0101;
}
header.header div.navigation.mobile-menu {
  width: 60px;
  height: 34px;
}
.mobile-menu-links {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100dvh;
  width: 100%;
  background-color: #fff;
  z-index: 500;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  overflow-y: auto;
}
.mobile-menu-links.show {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
.mobile-menu-links .user {
  border-bottom: 1px solid #0000001a;
}
.mobile-menu-links .user .user-image {
  width: 90px;
  height: 90px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: block;
}
.mobile-menu-links .user {
  margin-top: 90px;
}
.mobile-menu-links .user .user-info {
  display: flex;
  align-items: center;
}
.mobile-menu-links .user .user-info .user-image {
  width: 80px;
  height: 80px;
  margin-left: 15px;
}
.mobile-menu-links .user .user-info .user-name {
  display: flex;
  flex-direction: column;
}
.mobile-menu-links .user ul {
  padding: 15px 0;
}
.mobile-menu-links .user ul li,
.mobile-menu-links .other-links li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.mobile-menu-links .user ul li img,
.mobile-menu-links .user ul li i {
  margin-left: 15px;
}
.mobile-menu-links .other-links {
  padding: 15px 10px;
}
.mobile-menu-links .dark-light-mode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu-links .dark-light-mode .right-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mobile-menu-links .dark-light-mode .right-btn .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.mobile-menu-links .collapse-menu {
  position: relative;
}
.mobile-menu-links .collapse-menu .icon {
  position: absolute;
  left: 0;
}
.mobile-menu-links .collapse-menu + .menu {
  height: 0;
  overflow: hidden;
  padding: 0 20px 0 0 !important;
}
.mobile-menu-links .collapse-menu + .menu.un-collapse {
  height: unset;
  padding-top: 10px !important;
}
@media (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
  .show-sm {
    display: block;
  }
  .hidden-lg {
    display: block !important;
  }
}
/* end header */
/* start home page */
/* start wrapper section */
.wrapper {
  position: relative;
  height: 95dvh;
  background-image: linear-gradient(#0278ff, #00c2ff);
}
.wrapper .social-media {
  position: absolute;
  background-color: #fff;
  border-radius: 0 28px 28px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 0 28px 28px 0;
  -moz-border-radius: 0 28px 28px 0;
  -ms-border-radius: 0 28px 28px 0;
  -o-border-radius: 0 28px 28px 0;
}
@media (max-width: 767px) {
  .wrapper .social-media {
    left: 50%;
    top: unset;
    bottom: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    border-radius: 28px 28px 0 0;
    -webkit-border-radius: 28px 28px 0 0;
    -moz-border-radius: 28px 28px 0 0;
    -ms-border-radius: 28px 28px 0 0;
    -o-border-radius: 28px 28px 0 0;
    flex-direction: row;
  }
  .wrapper .social-media a {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
.wrapper .social-media a {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}
.wrapper div.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}
.wrapper div.container > div {
  width: 45%;
  height: 80%;
}
.wrapper div.container > div.right {
  height: 100%;
}
.wrapper div.container .left {
  width: 40%;
  position: relative;
  /* height: 500px; */
}
@media (max-width: 767px) {
  .wrapper div.container {
    flex-direction: column;
    justify-content: space-between;
    align-items: unset;
  }
  .wrapper div.container .left {
    width: 100%;
  }
}
.wrapper div.container .left img {
  position: absolute;
}
.wrapper div.container .left .first-union {
  top: 110px;
  left: 15%;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  animation-name: rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.wrapper div.container .left .secound-union {
  width: 120px;
  top: 50%;
  transform: translateY(-50%);
  right: 80px;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;

  animation-name: rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.wrapper div.container .left .frame-one {
  height: 80%;
  bottom: 0;
}
.wrapper div.container .left .teacher {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.wrapper div.container .left .frame-tow {
  bottom: 0;
}
@keyframes rotate {
  from {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.wrapper div.container .right {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.wrapper div.container .right .top {
  font-size: 10em;
  font-weight: bold;
  color: #fff;
}
.wrapper div.container .right .top p {
  margin: 0;
  padding: 0;
  height: 196px;
}
.wrapper div.container .right .bottom {
  margin-top: 5%;
}
.wrapper div.container .right .bottom p {
  font-family: Qatar2022 Arabic;
  padding: 8px 15px;
  background-color: #0f0f0f;
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  width: fit-content;
}
.wrapper div.container .right .bottom p.bottom-mobile-view {
  display: none;
}
@media (max-width: 767px) {
  .wrapper div.container > div.right {
    height: unset;
    width: 100%;
  }
  .wrapper div.container .right .top {
    width: 100%;
    font-size: 52px;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: center;
  }
  .wrapper div.container .right .top p {
    height: unset;
  }
  .wrapper div.container .right .bottom {
    display: flex;
  }
  .wrapper div.container .right .bottom p {
    display: none;
  }
  .wrapper div.container .right .bottom p.bottom-mobile-view {
    display: block;
    font-size: 17px;
    width: 100%;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    text-align: center;
  }
}
/* start offer bar */
.offer {
  height: 7dvh;
  background-image: url("./assets/Mask\ group.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.offer p {
  color: #fff;
}
.offer a.book-now {
  background-color: #f1cb34;
  color: #000 !important;
  font-weight: bold;
  padding: 5px 15px;
  margin-right: 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
/* start features section */
.features .container .row .feature {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.features .container .row .feature .icon {
  margin-left: 15px;
  font-size: 3em;
}
.features .container .row .feature .title p {
  font-size: 16px;
}
@media (max-width: 767px) {
  .features {
    border-bottom: 1px solid #0000001a;
  }
  .features .container .row {
    justify-content: center;
  }
  .features .container .row .feature {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 25px;
  }
  .features .container .row .feature .icon {
    margin-left: 0;
    margin-bottom: 25px;
  }
}
/* start teacher speak section */
.teacher-speak {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 80vh;
}
.teacher-speak > div {
  width: 50%;
}
.teacher-speak > div.right-image {
  display: flex;
  justify-content: flex-end;
}
.teacher-speak > div.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.teacher-speak > div.slider .slide {
  width: 50%;
}
.teacher-speak > div.slider .slide h3 {
  font-weight: bold;
}
.teacher-speak > div.slider .slide h3 span.gradient {
  background: linear-gradient(#0278ff, #bd00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 767px) {
  .teacher-speak {
    height: unset !important;
    flex-direction: column;
    border-bottom: 1px solid #0000001a;
    padding-bottom: 2em;
  }
  .teacher-speak > div {
    width: unset;
  }
  .teacher-speak > div.right-image {
    margin-bottom: 25px;
  }
  .teacher-speak > div.slider .slide {
    padding: 10px 15px;
    width: unset;
  }
}
/* start youtube shorts section */
.youtube-shorts .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.youtube-shorts .container .row {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.youtube-shorts .container .row .short h6 {
  font-size: 16px;
}
.youtube-shorts .container .row .short p {
  font-size: 15px;
}
@media (max-width: 767px) {
  .youtube-shorts {
    border-bottom: 1px solid #0000001a;
    padding-bottom: 2em;
  }
  .youtube-shorts .container .row {
    justify-content: unset;
  }
}
/* start courses section */
.courses .container .row {
  display: flex;
  justify-content: center;
}
.courses .container .row > div h4 {
  color: #000 !important;
}
.courses .container .row .first {
  background-color: #b6d6d0;
  padding: 0 10px;
  z-index: 1;
}
.courses .container .row .secund {
  background-color: #c1d5ea;
  padding: 0 10px;
  z-index: 1;
}
.courses .container .row .third {
  background-color: #fae285;
  padding: 0 10px;
}
.courses .container .row .secund img {
  width: 60%;
  margin-left: -10%;
}
.courses .container .row .first::before,
.courses .container .row .secund::before,
.courses .container .row .third::before {
  content: "1";
  position: absolute;
  bottom: -75px;
  left: 56%;
  transform: translateX(-56%);
  -webkit-transform: translateX(-56%);
  -moz-transform: translateX(-56%);
  -ms-transform: translateX(-56%);
  -o-transform: translateX(-56%);
  font-size: 197.78px;
  height: fit-content;
  color: #0000001a;
  font-weight: bold;
}
.courses .container .row .secund::before {
  content: "2";
}
.courses .container .row .third::before {
  content: "3";
}

.courses .container .row > div::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-image: url("./assets/Union-white.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.courses .container .row > div {
  height: 136px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.courses .container .row > div p {
  font-size: 197.78px;
  height: fit-content;
  color: #0000001a;
  font-weight: bold;
}
@media (max-width: 767px) {
  .courses {
    border-bottom: 1px solid #0000001a;
    padding-bottom: 2em;
  }
  .courses .container .row {
    padding-left: 10px;
    padding-right: 10px;
  }
  .courses .container .row > div {
    height: 104px;
  }
  .courses .container .row .first::before,
  .courses .container .row .secund::before,
  .courses .container .row .third::before {
    font-size: 110px;
    bottom: -40px;
  }
}
/* start first students section */
.first-students .container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.first-students .container-fluid .row {
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  overflow-x: auto;
}
.first-students .container-fluid .row .student-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.first-students .container-fluid .row .student-card .image {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 15px;
}
.first-students .container-fluid .row .student-card .student-score {
  padding: 5px 15px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(#0278ff, #bd00ff);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
@media (max-width: 767px) {
  .first-students .container-fluid .row {
    justify-content: unset;
  }
}
/* end home page */
/* start footer */
footer.footer {
  background-color: #060606;
}
footer.footer .contact-section {
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer.footer .contact-section .footer-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}
footer.footer .contact-section h4 {
  color: #fff;
}
footer.footer .contact-section .row {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
footer.footer .contact-section .row > div {
  color: #ffffffb2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  direction: ltr;
}
footer.footer .contact-section .row > div i {
  margin-left: 5px;
}
footer.footer .contact-section .footer-social-media {
  direction: ltr;
}
footer.footer .contact-section .footer-social-media a {
  color: #ffffffb2;
  font-size: 26px;
  margin-right: 10px;
}
footer.footer .copyright {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  direction: ltr;
  padding-bottom: 10px;
}
footer.footer .copyright .copyright-logo {
  width: 140px;
  height: 30px;
}
footer.footer .copyright p {
  color: #ffffffb2;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
@media (max-width: 767px) {
  footer.footer .copyright .copyright-logo {
    width: 50px;
    height: unset;
  }
  footer.footer .copyright p {
    font-size: 10px;
  }
}
/* end footer */
/* start auth page layout */
.auth-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 1000;
  background-color: #c1d5ea;
  display: flex;
  flex-direction: row-reverse;
}

.auth-layout .left-image {
  width:45%;
  height:100%;
  padding-left: 10px;
  position: relative;
  background-image: url(../src/assets/taha-assets/2.png)!important;
  background-size: cover;
}
@media (max-width: 767px) {
  .auth-layout .left-image {
    display: none;
  }
}
.auth-layout .left-image .back-btn {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40px;
  left:-25px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
}
.auth-layout .right {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* start login section */
.auth-layout .right .login-section {
  width: 40%;
  background-color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.auth-layout .right .login-section .icon-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth-layout .right .login-section .icon-title .icon {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.auth-layout .right .login-section .login-form > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  background-color: #e5e5e580;
  padding: 10px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.auth-layout .right .login-section .login-form > div input {
  all: unset;
  width: 100%;
}
.auth-layout .right .login-section .login-form > div input::placeholder {
  color: #0000004d;
}
.auth-layout .right .login-section .login-form > div input:focus::placeholder {
  color: transparent;
}
.auth-layout .right .login-section .login-form > div i {
  cursor: pointer;
  color: #919191;
}
.auth-layout .right .login-section .login-form .forget-password {
  font-size: 12px;
  color: #3779f8;
  text-align: end;
  margin-bottom: 5px;
}
.auth-layout .right .login-section .login-form button,
.auth-layout .right .login-section .login-form div.google-login {
  all: unset;
  padding: 10px 0;
  text-align: center;
  background-color: #3779f8;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}
.auth-layout .right .login-section .login-form div.google-login {
  background-color: #e7e7e7;
  color: #000;
  flex-direction: row-reverse;
}
/* .auth-layout .right .login-section .login-form a.google-login img {
  margin-right: 10px;
} */
.auth-layout .right .login-section .register {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.auth-layout .right .login-section .register a {
  color: #3779f8;
}
/* start register section */
.register-errors {
  max-height: 100px;
  overflow-y: auto;
  margin-top: 10px;
}
.auth-layout .right .register-section {
  width: 80%;
  background-color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.auth-layout .right .register-section .steps-bar {
  display: flex;
}
.auth-layout .right .register-section .steps-bar .step {
  width: 33.33%;
  height: 3px;
  background-color: #d9d9d9;
  position: relative;
}
.auth-layout .right .register-section .steps-bar .step:last-child {
  width: 0;
}
.auth-layout .right .register-section .steps-bar .step::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -7.5px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  background-color: #d9d9d9;
}
.auth-layout .right .register-section .steps-bar .step.completed::before,
.auth-layout .right .register-section .steps-bar .step.completed {
  background-color: #00bd99;
}
/* start first step register */
.upload-user-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-user-image .image {
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
}
.upload-user-image .image img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.upload-user-image .icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #e4e4e4;
  color: #000;
  position: absolute;
  bottom: 15px;
  left: 10px;
}
.upload-user-image p {
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #dfeeff80;
  color: #0278ff;
  font-size: 13px;
}
.upload-user-image p.remove-photo {
  color: #ff3838;
  background-color: #ff38381a;
  cursor: pointer;
}
.register-section .upload-user-image p.add-new-photo {
  cursor: pointer;
}
.auth-layout .right .register-section .form-steps .row {
  justify-content: center;
}
/* start global auth styles */
.input-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #e5e5e580;
  padding: 10px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
}
.input-style.error {
  border: 1px solid #ff0101 !important;
}
.input-style.upload {
  justify-content: space-between;
  cursor: pointer;
}
select {
  border: none;
  outline: none;
  margin-bottom: 10px;
  background-color: #e5e5e580;
  padding: 10px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
}
select::after {
  content: "test";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
select option:first-child {
  color: #0000004d;
}
select.error {
  border: 1px solid #ff0101 !important;
}
div.input-style input {
  all: unset;
  width: 100%;
}
div.input-style input::placeholder {
  color: #0000004d;
}
div.input-style input:focus::placeholder {
  color: transparent;
}
div.input-style i {
  cursor: pointer;
  color: #919191;
}
.next-prev-step {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.next-prev-step .next-step {
  all: unset;
  width: 20%;
  padding: 10px 0;
  color: #fff;
  background-color: #3779f8;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-align: center;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}
.next-prev-step .next-step.register-btn {
  width: fit-content;
  padding: 10px 15px;
}
.next-prev-step .prev-step {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000033;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .auth-layout {
    z-index: 499;
  }
  .auth-layout .right {
    padding: 0;
    align-items: flex-end;
  }
  .auth-layout .right .login-section,
  .auth-layout .right .register-section {
    width: 100%;
    height: 75%;
    border-radius: 30px 30px 0 0;
    -webkit-border-radius: 30px 30px 0 0;
    -moz-border-radius: 30px 30px 0 0;
    -ms-border-radius: 30px 30px 0 0;
    -o-border-radius: 30px 30px 0 0;
  }
  .auth-layout .right .register-section {
    overflow-y: auto;
  }
}
/* end auth page layout*/
/* start courses section */
.courses-title {
  height: 35vh;
  background-color: #b6d6d0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .courses-title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.courses-title .content {
  display: flex;
  align-items: center;
}
.courses-search-bar {
  border-bottom: 1px solid #0000001a;
}
.courses-search-bar .search-box {
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden;
}
.courses-search-bar .search-box input {
  all: unset;
  width: 100%;
}
.courses-search-bar .search-box input::placeholder {
  color: #00000080;
}
.courses-search-bar .search-box input,
.courses-search-bar .search-box .search-btn {
  padding: 10px;
}
.courses-search-bar .search-box .search-btn {
  background-color: #000;
}
.courses-search-bar .search-box .search-btn img {
  width: 26px;
  height: 18px;
}
.courses-section {
  border-bottom: 1px solid #0000001a;
}
/* start course box */
.course-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: block;
  padding-bottom: 10px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}
.course-box .course-image {
  height: 250px;
  position: relative;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}

.course-box .course-image .add-course-icon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
}
.course-box .course-title {
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #0000001a;
}
.course-box .course-title p {
  font-size: 14px;
  color: #00000080;
}
.course-box .course-time-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
.course-box .course-time-price .course-time .time {
  display: flex;
  align-items: center;
}
.course-box .course-time-price .course-time .time i {
  margin-left: 10px;
  font-size: 14px;
  color: #00000080;
}
.course-box .course-time-price .course-time .time p {
  font-size: 14px;
  color: #00000080;
}
.course-box .course-time-price .price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}
.course-box .go-to-course {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.course-box .go-to-course a,
.course-box .go-to-course button {
  all: unset;
  padding: 10px 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 45%;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.course-box .go-to-course a.enter {
  color: #000;
  background-color: #fff;
}
.course-box .go-to-course button.enroll {
  color: #fff;
  background-color: #3779f8;
}
/* end courses section */
/* start course page */
.course-details {
  min-height: 110vh;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: end;
}
.course-details::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: -1;
  background-color: #b6d6d0;
}
.course-details .details-box {
  width: 50%;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  padding: 15px;
  margin-top: 8em;
}
@media (max-width: 767px) {
  .course-details {
    padding-left: 10px;
    padding-right: 10px;
  }
  .course-details .details-box {
    width: 100%;
  }
}
.course-details .details-box .course-image {
  height: 400px;
  overflow: hidden;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}
.course-details .details-box .course-title-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #0000001a;
}
.course-details .details-box .course-title-price .course-title {
  width: 50%;
}
@media (max-width: 767px) {
  .course-details .details-box .course-title-price .course-title {
    width: 70%;
  }
}
.course-details .details-box .course-title-price .course-title p {
  font-size: 14px;
  color: #00000080;
}
.course-details .details-box .course-title-price .price {
  font-size: 32px;
  font-weight: bold;
}
.course-details .details-box .time-content-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course-details .details-box .time-content-details .time-details .time {
  font-size: 14px;
  color: #00000080;
  display: flex;
  align-items: center;
}
.course-details .details-box .time-content-details .time-details .time i {
  margin-left: 10px;
}
.course-details .details-box .time-content-details .content-details .details,
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .right {
  display: flex;
  align-items: center;
}
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .right,
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .left {
  padding: 0 15px;
  font-size: 14px;
  color: #00000080;
}
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .right {
  display: flex;
  align-items: center;
}
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .right
  .icon {
  margin-left: 10px !important;
}
.course-details
  .details-box
  .time-content-details
  .content-details
  .details
  .left {
  font-weight: bold;
  border-right: 1px solid #00000080;
  padding-right: 10px !important;
}
.course-details .details-box .btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.course-details .details-box .btns .subscribe-now {
  width: 60%;
  padding: 10px 0;
  color: #fff;
  background-color: #0278ff;
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}
.course-details .details-box .btns .add-to-cart {
  background-color: #ebeaea;
  color: #000;
  width: 35%;
  padding: 10px 0;
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}
/* start content collapse */
.course-content-collapse {
  margin-bottom: 10px;
}
.course-content-collapse .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border: 1px solid #00000080;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  cursor: pointer;
}
.course-content-collapse .top-bar .icon i {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.course-content-collapse .top-bar.open {
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
}
.course-content-collapse .collapsed-data {
  padding: 0px 10px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  height: 0;
  overflow: hidden;
}
.course-content-collapse .collapsed-data.open {
  height: unset;
  padding: 15px 10px;
  border-width: 0 1px 1px 1px;
  border-color: #00000080;
  border-style: solid;
  border-radius: 0 0 20px 20px;
  -webkit-border-radius: 0 0 20px 20px;
  -moz-border-radius: 0 0 20px 20px;
  -ms-border-radius: 0 0 20px 20px;
  -o-border-radius: 0 0 20px 20px;
}
/* end course page */
/* start course files */
.course-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.course-files .file {
  width: calc(95% / 4);
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-align: center;
}
.course-files .file .icon {
  /* color: #F1CB34 */
}
@media (max-width: 767px) {
  .course-files .file {
    width: 100%;
  }
  .course-files {
    flex-direction: column;
  }
}
/* start user profile */
.profile-layout {
  padding-top: 8em;
  display: flex;
}
/* sidebar */
.profile-layout .user-profile-sidebar {
  padding: 10px 0;
  width: 25%;
}
@media (max-width: 767px) {
  .profile-layout .user-profile-sidebar {
    display: none;
  }
  .profile-layout {
    padding-top: 1.8em;
  }
}
.profile-layout .user-profile-sidebar > h5 {
  padding-right: 25px;
}
.profile-layout .user-profile-sidebar .profile-links li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 25px;
  font-size: 16px;
  color: #000000cc;
  position: relative;
  cursor: pointer;
}
.profile-layout .user-profile-sidebar .profile-links li.active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background-color: #ff0202 !important;
}
.profile-layout .user-profile-sidebar .profile-links li i {
  margin-left: 15px;
  font-size: 24px;
  color: #000000cc;
}
/* start profile page */
.profile-layout .profile-section .profile-image-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0000001a;
  padding-bottom: 20px;
}
.profile-layout .profile-section .group.year-education {
  margin-top: 15px;
}
.profile-layout .profile-section .group.year-education .selects {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-layout .profile-section .group.year-education .selects select {
  width: 45% !important;
}
.profile-layout .profile-section .group input,
.profile-layout .profile-section .group select {
  all: unset;
  padding: 10px;
  background-color: #ebeaea;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.profile-layout .profile-section .group h6 {
  color: #00000080;
  font-size: 12px;
}
.profile-layout .profile-section .profile-image-progress .user-image {
  display: flex;
  align-items: center;
}
.profile-layout .profile-section .profile-image-progress .user-image .image {
  width: 90px;
  height: 93px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-left: 15px;
}
.profile-layout
  .profile-section
  .profile-image-progress
  .user-image
  .change-btns
  label,
.profile-layout
  .profile-section
  .profile-image-progress
  .user-image
  .change-btns
  button {
  all: unset;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}
.profile-layout
  .profile-section
  .profile-image-progress
  .user-image
  .change-btns
  label {
  color: #0278ff;
  background-color: #dfeeff;
  margin-left: 10px;
}
.profile-layout
  .profile-section
  .profile-image-progress
  .user-image
  .change-btns
  button {
  background-color: #ebeaea;
  color: #1e1e1e;
}
.profile-layout .profile-section .name-email {
  border-bottom: 1px solid #0000001a;
  padding-bottom: 15px;
}
.profile-layout .profile-section .name-email .name .group .inputs,
.profile-layout .profile-section .name-email .email .group div {
  display: flex;
  align-items: center;
}
.profile-layout .profile-section .name-email .name .group .inputs input,
.profile-layout .profile-section .name-email .email .group input {
  width: 100%;
}
.profile-layout .profile-section .name-email .name .inputs input:first-child {
  margin-left: 10px;
}
.profile-layout .profile-section .birthdate-phone {
  display: flex;
  justify-content: space-between;
}
.profile-layout .profile-section .birthdate-phone > div.birthdate {
  margin-left: 10px;
}
.profile-layout .profile-section .birthdate-phone > div {
  width: 100%;
}
.profile-layout .profile-section .birthdate-phone > div .group div {
  display: flex;
}
.profile-layout .profile-section .birthdate-phone > div .group div input {
  width: 100%;
}
.profile-layout .profile-section .change-password {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.profile-layout .profile-section .change-password .group {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 10px;
}
.profile-layout .profile-section .change-password .group div {
  display: flex;
}
.profile-layout .profile-section .change-password .group div input {
  width: 100%;
}
@media (max-width: 767px) {
  .profile-layout .profile-section .name-email .name .inputs {
    flex-wrap: wrap;
  }
  .profile-layout .profile-section .name-email .name .inputs input {
    margin: 0 0 10px 0 !important;
  }
  .profile-layout .profile-section .birthdate-phone {
    flex-direction: column;
  }
  .profile-layout .profile-section .change-password .group {
    width: 100%;
    padding: 0;
  }
  .profile-layout .profile-section .group.year-education .selects select {
    width: 100% !important;
    margin-top: 10px;
  }
  .profile-layout .profile-section .group.year-education .selects {
    flex-wrap: wrap;
  }
}
.profile-layout .profile-section .save-data button {
  all: unset;
  cursor: pointer;
  padding: 10px 25px;
  color: #fff;
  background-color: #00bd99;
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
@media (max-width: 767px) {
  .profile-layout .profile-section .profile-image-progress {
    flex-direction: column;
  }
  .profile-layout .profile-section .save-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-layout .profile-section .save-data button {
    width: 100%;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
  .profile-layout .profile-section .profile-image-progress {
    padding-top: 10px;
  }
}
/* start my courses section */
.profile-layout .my-courses-section .row .my-course-box {
  border: 1px solid #b6d6d0;
  padding: 15px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}
/* .my-course-box .active-badge {
  position: absolute;
  width: fit-content !important;
  height: unset !important;
  top: 0;
  left: 0;
  padding: 8px 5px !important;
  background-color: #FF3838;
  color: #fff;
} */
.profile-layout .my-courses-section .row .my-course-box > div {
  width: 20%;
  height: 100%;
  padding: 0 10px;
}
.profile-layout .my-courses-section .row .my-course-box > div.course-image {
  height: 107px !important;
}
.profile-layout .my-courses-section .row .my-course-box p,
.profile-layout .my-courses-section .row .my-course-box .course-time .time i {
  font-size: 14px;
  color: #00000080;
}
.profile-layout .my-courses-section .row .my-course-box > div:not(:last-child) {
  border-left: 1px solid #0000001a;
}
.profile-layout .my-courses-section .row .my-course-box .course-time,
.profile-layout .my-courses-section .row .my-course-box .course-title-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-layout .my-courses-section .row .my-course-box .course-time .time {
  display: flex;
  align-items: center;
}
.profile-layout .my-courses-section .row .my-course-box .course-time .time i {
  margin-left: 10px;
}
.profile-layout .my-courses-section .row .my-course-box .course-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.profile-layout .my-courses-section .row .my-course-box .to-course {
  display: flex;
  align-items: center;
}
.profile-layout .my-courses-section .row .my-course-box .to-course a {
  width: 100%;
  background-color: #0278ff;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
@media (max-width: 767px) {
  .profile-layout .my-courses-section .row {
    padding: 0 10px 0 10px;
  }
  .profile-layout .my-courses-section .row .my-course-box {
    flex-direction: column;
    padding: 5px;
  }
  .profile-layout .my-courses-section .row .my-course-box > div {
    width: 100%;
    height: unset;
    border: none !important;
    margin: 0 0 20px 0;
  }
  .profile-layout .my-courses-section .row .my-course-box > div.course-image {
    height: 150px;
  }
}
/* start exam score section */
.profile-layout .exam-score-section .table thead th:first-child {
  color: #0278ff;
  background-color: #dfeeff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.profile-layout .exam-score-section .table thead th {
  font-size: 14px;
  color: #767676;
  text-align: center;
  margin-bottom: 10px;
}
.profile-layout .exam-score-section .table tbody td {
  font-size: 13px;
  text-align: center;
}

.profile-layout .exam-score-section .table tbody td.score-bar .bar {
  width: 100%;
  height: 3px;
  background-color: #ff0101;
  position: relative;
}
.profile-layout .exam-score-section .table tbody td.score-bar .bar span {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background-color: #00bd99;
}
.profile-layout .exam-score-section .table tbody td a.retake {
  background-color: #00bd99;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .profile-layout .exam-score-section {
    width: 100%;
    min-height: 100vh;
    overflow-x: auto;
    padding: 10px 15px;
  }
  .profile-layout .exam-score-section .table {
    width: 100%;
  }
  .profile-layout .exam-score-section .table thead th {
    width: fit-content;
    text-wrap: nowrap;
  }
}
/* end user profile */
/* start user course preview */
.user-course-preview-title {
  height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-color: #0278ff;
  color: #fff;
}
.user-course-preview-title p {
  width: 40%;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
}

.user-course-preview-content {
  padding-top: 15px;
}
.user-course-preview-content > .title {
  display: flex;
  justify-content: center;
}
.user-course-preview-content h6 {
  text-align: center;
  width: 40%;
  color: #1e1e1e;
  line-height: 32px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .user-course-preview-title {
    justify-content: center;
  }
  .user-course-preview-title p {
    width: 100%;
  }
  .user-course-preview-content h6,
  .user-course-preview-content > .title {
    width: 100%;
  }
}
.total-course-exams {
  padding: 10px 0;
}
.total-course-exams > div {
  border-radius: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 10px;
  text-align: start;
}
.total-course-exams > div .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total-course-exams > div .title span {
  font-size: 13px;
  color: #1e1e1e;
  opacity: 0.8;
}
.total-course-exams > div a {
  display: block;
  padding: 10px;
  border-radius: 8px;
  background-color: #0278ff;
  color: #fff;
  text-align: center;
}
/* start course unit box */
.course-unit-box {
  width: 20% !important;
  text-align: center;
  border-radius: 23px;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  -ms-border-radius: 23px;
  -o-border-radius: 23px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .user-course-preview-content .row {
    padding: 0 10px;
  }
  .course-unit-box {
    width: 100% !important;
  }
}
.course-unit-box h6 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding: 15px 0;
}
.course-unit-box .action {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}
.course-unit-box .action .action-icon {
  color: #1e1e1e;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}
.course-unit-box .action,
.course-unit-box ul.lessons {
  padding-left: 10px;
  padding-right: 10px;
}
.course-unit-box .action .action-icon.selected,
.course-unit-box ul.lessons li.selected {
  background-color: #0278ff;
  color: #fff;
}
.course-unit-box .action .action-icon p {
  font-size: 13px;
}
.course-unit-box ul.lessons {
  margin-bottom: 25px;
}
.course-unit-box ul.lessons li {
  display: block;
  text-align: center;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #eee;
  transition: all 0.5s;
}
.course-unit-box ul.lessons li:hover {
  background-color: #ddd;
}
.course-unit-box ul.lessons li.selected:hover {
  background-color: #0278ff;
  color: #fff;
}
.course-unit-box ul.lessons li a {
  width: 100%;
  height: 100%;
  display: block !important;
  padding: 10px 0;
}
.course-unit-box .start {
  display: block;
  width: 101%;
  height: 101%;
  padding: 15px 0;
  text-align: center;
  background-color: #0278ff;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin-top: 15px;
}
/* end user course preview */
/* start my course watch page */
.my-course-watch-page {
  padding-top: 8em;
}
.my-course-watch-page .video-container,
.my-course-watch-page iframe {
  width: 100%;
  height: 80vh;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 24px;
}

.my-course-watch-page .video-container .play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  font-size: 60px;
  cursor: pointer;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
}
.my-course-watch-page .video-container:hover .play-pause-btn,
.my-course-watch-page .video-container .play-pause-btn.pause {
  opacity: 1;
}
.my-course-watch-page .lesson-info .lesson-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.my-course-watch-page .lesson-info .lesson-title h5 {
  font-size: 24px;
  font-weight: 500;
}
.my-course-watch-page .lesson-info .lesson-title .current-lesson {
  font-size: 20px;
  font-weight: 500;
}
.my-course-watch-page .lesson-info {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #0000001a;
}
.my-course-watch-page .lesson-info .lesson-description {
  padding: 15px 10px;
  background-color: #ececec;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.my-course-watch-page .lesson-info .lesson-description .description {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
}

.my-course-watch-page .quiz-section {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0278ff;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  color: #fff;
  margin: 30px 0;
  border-bottom: 1px solid #0000001a;
}
.my-course-watch-page .quiz-section .start-quiz {
  width: 50%;
}
.my-course-watch-page .quiz-section .start-quiz h6 {
  font-size: 32px;
}
.my-course-watch-page .quiz-section .start-quiz p {
  font-size: 19.78px;
}
.my-course-watch-page .quiz-section .start-quiz a {
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 500;
  background-color: #ffffff;
  color: #1e1e1e;
  width: 50%;
  display: block;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
@media (max-width: 767px) {
  .my-course-watch-page {
    padding-top: 3em;
  }
  .my-course-watch-page .video-container {
    height: unset;
  }
  .my-course-watch-page .lesson-info .lesson-description .description,
  .my-course-watch-page .quiz-section .start-quiz {
    width: 100%;
  }
  .my-course-watch-page .quiz-section .start-quiz {
    text-align: center;
  }
  .chapters-container {
    padding: 0 10px;
  }
}
/* end my course watch page */
/* start quiz page */
.breadcrumb-exit-container {
  padding-top: 8em;
}
.breadcrumb-exit-container .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcrumb-exit-container .breadcrumb {
  direction: rtl;
}
.breadcrumb-exit-container .breadcrumb .breadcrumb-item {
  padding: 10px;
  margin-left: 15px;
  position: relative;
}
.breadcrumb-exit-container .breadcrumb .breadcrumb-item::before {
  all: unset;
}
.breadcrumb-exit-container
  .breadcrumb
  .breadcrumb-item:not(:last-child)::after {
  content: "\f053";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.breadcrumb-exit-container .exit-quiz {
  all: unset;
  cursor: pointer;
  padding: 8px 20px;
  background-color: #fff;
  color: #ff3838;
  border: 1px solid #ff3838;
  transition: all 0.5s;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.breadcrumb-exit-container .exit-quiz:hover {
  background-color: #ff3838;
  color: #fff;
}
.quiz-area-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}
.quiz-area-container .counter {
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: #00bd99;
  padding: 6px 10px;
  border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  -ms-border-radius: 15px 15px 0 0;
  -o-border-radius: 15px 15px 0 0;
  color: #fff;
}
@media (max-width: 767px) {
  .quiz-area-container .counter {
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    border-radius: 0 15px 15px 0;
    -webkit-border-radius: 0 15px 15px 0;
    -moz-border-radius: 0 15px 15px 0;
    -ms-border-radius: 0 15px 15px 0;
    -o-border-radius: 0 15px 15px 0;
  }
  .quiz-area-container .counter i {
    display: none;
  }
  .quiz-area-container .counter .time {
    font-weight: bold;
  }
}
.quiz-area-container .counter .time {
  display: flex;
  align-items: center;
  margin-right: 10px;
  direction: ltr;
}
.quiz-area-container .counter .time div {
  padding: 5px;
}
.quiz-area-container .quiz-area {
  width: 80%;
  padding: 30px 15px;
  background-color: #fff;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.quiz-area-container .quiz-area .next-question,
.quiz-area-container .quiz-area .submit {
  all: unset;
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
  background-color: #0278ff;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.quiz-area-container .quiz-area .submit {
  background-color: #00bd99;
}
.quiz-area-container .quiz-area .question .question-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.quiz-area-container
  .quiz-area
  .question
  .question-title-container
  .question-title {
  width: 100%;
  line-height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quiz-area-container
  .quiz-area
  .question
  .question-title-container
  .question-title
  > div {
  width: 40%;
  height: 150px;
}

.quiz-area-container .quiz-area .question .answers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  border-radius: 8px;
}
.quiz-area-container .quiz-area .question .answers .answer {
  padding: 10px;
  width: 48%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;
}
.quiz-area-container .quiz-area .question .answers .answer.active {
  background-color: #0278ff;
  color: #fff;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .breadcrumb-exit-container {
    padding-top: 2em;
  }
  .quiz-area-container .quiz-area {
    width: 100%;
  }
  .quiz-area-container .quiz-area .question .question-title-container {
    flex-direction: column;
  }
  .quiz-area-container
    .quiz-area
    .question
    .question-title-container
    .question-title {
    width: 100%;
  }
  .quiz-area-container .quiz-area .question .answers .answer {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #0000001a;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}
.quiz-area-container .questions-pagination {
  display: flex;
  width: 80%;
  padding: 0 15px;
  justify-content: start;
}
.quiz-area-container .questions-pagination li {
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 0 0 7px 7px;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  -ms-border-radius: 0 0 7px 7px;
  -o-border-radius: 0 0 7px 7px;
}
.quiz-area-container .questions-pagination li.active {
  color: #fff;
  background-color: #0278ff;
}

/* end quiz page */
/* start security login page */
.security-login-container {
  padding: 0 25px;
}
.security-login-container .image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.security-login-container .quick-info > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.security-login-container .quick-info > div .icon,
.security-login-container .quick-info > div .login-logout-count {
  display: flex;
  align-items: center;
}
.security-login-container .quick-info > div .icon i {
  margin-left: 10px;
}
.security-login-container .quick-info > div .icon p {
  font-size: 15px;
  font-weight: 500;
}
.security-login-container .quick-info > div .login-logout-count > div span {
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
}
.security-login-container
  .quick-info
  > div
  .login-logout-count
  > div.login-count
  i {
  color: #00bd99;
}
.security-login-container
  .quick-info
  > div
  .login-logout-count
  > div.logout-count
  i {
  color: #ff0101;
}
.security-login-container .table {
  text-align: center;
  margin-top: 40px;
}
.security-login-container .table thead tr th {
  color: #767676;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .security-login-container .table-section {
    width: 100% !important;
    overflow-x: auto;
  }
  .security-login-container .table-section .table {
    width: 100%;
  }
  .security-login-container .table-section .table thead tr th,
  .security-login-container .table-section .table tbody tr td {
    text-wrap: nowrap;
  }
  .security-login-container .quick-info > div .icon {
    width: 60%;
  }
  .security-login-container .quick-info > div .icon p {
    font-size: 13px;
  }
  .security-login-container .quick-info > div .login-logout-count > div span {
    padding: 10px 5px;
  }
}
/* end security login page */
/* start level indicator page */
.level-indicator-page .chart-container {
  overflow-x: auto;
}
.level-indicator-page .chart-container canvas {
  /* width: 100%; */
  height: 100%;
}
@media (max-width: 767px) {
  .level-indicator-page .chart-container {
    width: 100%;
  }
}
/* end level indicator page */
/* start notification page */

.notification-page .notification-box {
  padding: 15px 10px;
  margin-bottom: 15px;
  position: relative;
}
.notification-page .notification-box .notification-title {
  font-size: 15px;
  font-weight: 500;
}
.notification-page .notification-box .notification-body,
.notification-page .notification-box .notification-time {
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.notification-page .notification-box .pin-icon {
  cursor: pointer;
  position: absolute;
  padding: 15px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.notification-page .notification-box .new-badge {
  position: absolute;
  margin-top: -15px;
  top: 0;
  left: 0;
  padding: 5px 15px;
  text-align: center;
  background-color: #ff0101;
  color: #fff;
  border-radius: 8px 8px 8px 0;
  -webkit-border-radius: 8px 8px 8px 0;
  -moz-border-radius: 8px 8px 8px 0;
  -ms-border-radius: 8px 8px 8px 0;
  -o-border-radius: 8px 8px 8px 0;
}
.notification-page .notification-box .new-badge::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  border-width: 5px;
  border-color: #ff0101 transparent transparent transparent;
  border-style: solid;
}
@media (max-width: 767px) {
  .notification-page {
    min-height: 100vh;
  }
}
/* end notification page */
@keyframes transformation {
  from {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    opacity: 0;
  }
}
.wrapper .right {
  cursor: pointer;
}

.wrapper .right .top.transform p,
.wrapper .right .bottom.transform p {
  animation: transformation 1s ease-out 0s 1;
  -webkit-animation: transformation 1s ease-out 0s 1;
}
.preview-attach-image {
  display: flex;
  align-items: center;
}
.preview-attach-image img {
  width: 20%;
  height: 50px;
  margin-left: 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
/* start notfound page */
.notfound-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
/* end notfound page */
/* start placeholder box */
.placeholder-box {
  display: flex;
  justify-content: center;
}
.placeholder-box .loading-bar {
  text-align: center;
  font-size: 22px;
}
/* end placeholder box */
/* start subscriptions courd loadin page */
.subscription-gourd,
.loading-gurad {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.subscription-gourd a,
.subscription-gourd a:hover {
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #0278ff;
  color: #fff;
  margin-top: 10px;
}
.quiz-before-lesson .quiz-details {
  padding: 15px;
  border-radius: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 40%;
}

.quiz-before-lesson .quiz-details h5 {
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.quiz-before-lesson .quiz-details p {
  padding: 10px 0;
  font-size: 15px;
}
.quiz-before-lesson .quiz-details .details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .quiz-before-lesson .quiz-details {
    width: 100%;
  }
  .quiz-before-lesson .quiz-details .details {
    flex-direction: column;
  }
  .quiz-before-lesson .quiz-details .details > div {
    margin-bottom: 10px;
  }
}
.quiz-before-lesson .quiz-details .details > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz-before-lesson .quiz-details .details > div {
  font-size: 12px;
}
.quiz-before-lesson .quiz-details a {
  display: block;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
/* end subscriptions courd loadin page */
/* start welcome overlay */
.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s;
  opacity: 1;
  z-index: 999999;
  padding: 10px 15px;
}
.welcome-overlay .title {
  margin-bottom: 15px;
}
.welcome-overlay .image-card {
  width: 25%;
  height: 400px;
  border-radius: 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.welcome-overlay .image-card .image-container {
  height: 80%;
  width: 100%;
}
.welcome-overlay .image-card .teacher-name {
  text-align: center;
  background-color: #0278ff;
  color: #fff;
  padding: 15px;
  height: 20%;
}
@media (max-width: 767px) {
  .welcome-overlay .image-card {
    width: 100%;
  }
}
/* end welcome overlay */
/* start lesson by code page */
.lesson-by-code {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesson-by-code form {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
@media (max-width: 767px) {
  .lesson-by-code form {
    width: 100%;
  }
}
.lesson-by-code form > div {
  width: 100%;
}
.lesson-by-code form .right {
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #e7e7e7;
  width: 100%;
}
.lesson-by-code form .right input,
.lesson-by-code form .right button {
  all: unset;
  padding: 15px;
}
.lesson-by-code form .right input {
  width: 70%;
}
.lesson-by-code form .right button {
  width: 30%;
  background-color: #000;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.lesson-by-code form .right button i {
  margin-left: 10px;
}
.lesson-by-code form .left {
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  height: 150px;
  padding: 15px;
  margin-bottom: 15px;
}
.lesson-by-code form .left i {
  font-size: 25px;
  color: #ff0101;
  margin-bottom: 15px;
}
/* end lesson by code page */
/* scrool bar */
/* لعرض شريط التمرير في متصفح Chrome و Edge و Safari */
::-webkit-scrollbar {
  width: 12px; /* عرض شريط التمرير */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* لون الخلفية */
}

::-webkit-scrollbar-thumb {
  background: #ff7b00; /* لون شريط التمرير */
}

::-webkit-scrollbar-thumb:hover {
  background: #ffe100; /* لون شريط التمرير عند التمرير */
}

/* لعرض شريط التمرير في متصفح Firefox */
scrollbar {
  width: 12px; /* عرض شريط التمرير */
  background: #f1f1f1; /* لون الخلفية */
}

scrollbar-thumb {
  background: #888; /* لون شريط التمرير */
}

scrollbar-thumb:hover {
  background: #555; /* لون شريط التمرير عند التمرير */
}

/* loder */
/* From Uiverse.io by Nawsome */
/* From Uiverse.io by Ratinax */ 
.loader {
  width: 100%!important;
  height: 100vh!important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: not-allowed;
  scale: 0.7;
}

.central {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 0.5em 1em 1em cyan,
    -0.5em 0.5em 1em lime,
    0.5em -0.5em 1em white,
    -0.5em -0.5em 1em yellow;
}

.external-shadow {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0.5em 0.5em 3em cyan,
    -0.5em 0.5em 3em lime,
    0.5em -0.5em 3em white,
    -0.5em -0.5em 3em yellow;
  z-index: 999;
  animation: rotate 3s linear infinite;
  background-color: #212121;
}

.intern {
  position: absolute;
  color: white;
  z-index: 9999;
}

.intern::before {
  content: "100%";
  animation: percent 2s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes percent {
  0% {
    content: '0%';
  }

  25% {
    content: '25%';
  }

  33% {
    content: '33%';
  }

  42% {
    content: '42%';
  }

  51% {
    content: '51%';
  }

  67% {
    content: '67%';
  }

  74% {
    content: '74%';
  }

  75% {
    content: '75%';
  }

  86% {
    content: '86%';
  }

  95% {
    content: '95%';
  }

  98% {
    content: '98%';
  }

  99% {
    content: '99%';
  }
}