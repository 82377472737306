.advert{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  overflow: hidden;
  background-color:#FFBE00 ;
}
.advert-left{
  width:50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.advert .text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: white;
}
.advert .text h3{
  line-height: 1.8;
  font-weight:700;
  font-size:35px;
}
.advert .text p{
  font-size: 19px;
  font-weight: 600;
  color: aliceblue!important;
}
.advert-left img{
  width: 300px;
}
.advert-left h1{
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 1.8;
}
.advert-left p{
  line-height: 1.8;
  border-bottom: 5px solid rgb(122, 90, 249);
  padding-bottom: 10px;
}
.advert-right{
  width:100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}
.advert-right .adv{
  width: 90%;
  margin: 20px auto;
  background-color:rgb(249, 249, 249);
  padding: 20px;
  border-radius: 20px;
  color: rgb(19, 14, 14);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  gap: 0px;
  cursor: pointer;
  /* height: 300px; */
  padding-bottom:40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-image:linear-gradient(rgba(255, 161, 20, 0.5), rgba(20,20,20,.4)),url('../../../../assets/taha-assets/ai-generated-8787379_1280.jpg');
  background-size: cover;
}.advert-right .twoo{
  background-size: cover;
  background-image:linear-gradient(rgba(242, 161, 40, 0.5), rgba(20,20,20,.4)),url('../../../../assets/taha-assets/ai-generated-8684144_640.jpg');
}
.advert-right .threee{
  background-image:linear-gradient(rgba(252, 194, 94, 0.5), rgba(20,20,20,.4)),url('../../../../assets/taha-assets/ai-generated-8813119_640.jpg');
  background-size: cover;
}
.adv .dev-icon{
  font-size: 40px;
  color:rgb(122, 90, 249);
}
.advert-right .adv:hover .dev-icon{
color:rgb(255, 255, 255);
transition: 0.5s;
}