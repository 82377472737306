.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  position: relative;
  background-size: cover;
  overflow: hidden;
}
.home {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  /* margin-top: 20px; */
}
.home .home-left {
  /* width:35%; */
  object-fit: contain;
  position: relative !important;
  /* height: 90%; */
}
.home .home-left .img1 {
  width: 100%;
  margin: auto;
  z-index: 333333;
  height: 100%;
}
.home-left .back-img {
  display: none;
}
.home .home-right {
  width: 40%;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  gap: 20px !important;
  z-index: 20;
  text-align: center;
  font-size: 40px;
}
.home .home-right h1 {
  font-size: 44px;
  margin-top: 20px;
  font-weight: 800;
  color: #000000;
}
.home-right h1 span {
  color: #ffbe00;
  font-weight: 800;
}
.home-content .home-down {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.home-content .home-down img {
  width: 100%;
  height: max-content;
  background-color: transparent;
  height: 450px;
}

.t {
  position: absolute;
  top: 100px;
  right:-40px;
  width: 130px;
}
.tt {
  position: absolute;
  top:120px;
  right:40px;
  width: 100px;
}
.hh {
  position: absolute;
  bottom:130px;
  right:40px;
  width: 100px;
}
.h {
  position: absolute;
  top: 120px;
  left:0px;
  width: 100px;
}
.s {
  position: absolute;
  bottom:120px;
  right:-60px;
  z-index: 20;
  width: 90px;
}
.l {
  position: absolute;
  bottom:120px;
  left:0px;
  width: 90px;
}
/* From Uiverse.io by vinodjangid07 */
.scroll {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 200;
}
.scroll .button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255, 157, 0);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.scroll .svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.scroll .svgIcon path {
  fill: white;
}

.scroll .button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 196, 0);
  align-items: center;
}

.scroll .button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.scroll .button::before {
  position: absolute;
  bottom: -20px;
  content: "الصعود الى الأعلى ";
  color: rgb(17, 6, 6);
  /* transition-duration: .3s; */
  font-size: 0px;
  font-weight: 500;
}

.scroll .button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}
